import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
export const formatDate = (date : string) => dayjs(date).format('MMMM D, YYYY h:mm A')

export const formatDateInUTC =  (date : string) => dayjs.utc(date).format('MMMM D, YYYY h:mm A');


export const calculateTimeDifference = (dateString: string | number | Date | dayjs.Dayjs | null | undefined) => {
    if(dateString===null){
      return { displayTime: 'Never used', fullDate:'00-00-00 00:00:00' };
    }
    const date = dayjs(dateString);
    const now = dayjs();

    const years = now.diff(date, 'year');
    const months = now.diff(date, 'month') % 12;
    const days = now.diff(date, 'day') % 30;
    const hours = now.diff(date, 'hour') % 24;
    const minutes = now.diff(date, 'minute') % 60;
    const seconds = now.diff(date, 'second') % 60;

    let timeParts = [];
    if (years > 0) timeParts.push(`${years}y`);
    else if (months > 0) timeParts.push(`${months}m`);
    else if (days > 0) timeParts.push(`${days}d`);
    else if (hours > 0) timeParts.push(`${hours}h`);
    else if (minutes > 0) timeParts.push(`${minutes}min`);
    else if (seconds > 0) timeParts.push(`${seconds}s`);
    const displayTime = timeParts.length > 0 ? timeParts.join(' ') : '0s';

    return { displayTime, fullDate: date.format('YYYY-MM-DD HH:mm:ss') };
  };


  export const formatMilliseconds = (ms: number) => {
    const minutes = Math.floor(ms / 60000);     // Calculate whole minutes
    const seconds = Math.floor((ms % 60000) / 1000);  // Calculate whole seconds (remaining after minutes)
    const milliseconds = ms % 1000;             // Get remaining milliseconds

    let formattedTime = '';

    if (minutes > 0) {
        formattedTime += `${minutes}m `;
    }
    if (seconds > 0) {
        formattedTime += `${seconds}s `;
    }
    if (milliseconds > 0) {
        formattedTime += `${milliseconds}ms`;
    }
    return formattedTime.trim(); 
}
