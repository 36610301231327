import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Tooltip, Alert, IconButton } from '@mui/material';
import { Cancel, Edit, Save } from '@mui/icons-material';
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { getTableList, updateTableName } from '../api/Api';
import { TableOptionType } from './AddUser';

const AvailableDbTable = (props: any) => {
    const [tableList, setTableList] = useState<TableOptionType[]>([]);
    const [editRowId, setEditRowId] = useState<number | null>(null);
    const [editFormData, setEditFormData] = useState<TableOptionType>({ id: 0, tableName: '', tableCode: '' });
    const [validationError, setValidationError] = useState<string | null>(null);

    const listOfTable = useCallback(() => {
        getTableList().then(response => {
            setTableList(response.data);
        }).catch(error => {
            props.handleSnackBar(true, "error", error.response.data.message);
        });
    }, [props])

    useEffect(() => {
        if (tableList.length === 0) {
            listOfTable();
        }
    }, [listOfTable, tableList]);

    const validateFormData = (): string | null => {
        if (!editFormData.tableCode || !editFormData.tableName) {
            return 'Both Table Code and Table Name are required.';
        }
        return null;
    };

    const handleEditClick = (id: number) => {
        const selectedRow = tableList.find(item => item.id === id);
        if (selectedRow) {
            setEditRowId(id);
            setEditFormData(selectedRow);
            setValidationError(null);
        }
    };

    const handleSaveClick = () => {
        const error = validateFormData();
        if (error) {
            setValidationError(error);
            return;
        }
        setValidationError(null);

        updateTableName(editFormData).then(response => {
            props.handleSnackBar(true, 'success', response.data.message);
            listOfTable();
        }).catch(error => {
            props.handleSnackBar(true, "error", error.response.data.message);
        });
        setEditRowId(null);
    };

    const handleCancelClick = () => {
        setEditRowId(null);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const columns: GridColDef[] = [
        {
            field: 'tableCode',
            headerName: 'Table Code',
            flex: 1
        },
        {
            field: 'tableName',
            headerName: 'Table Name',
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                editRowId === params.row.id ? (
                    <TextField
                        name="tableName"
                        value={editFormData.tableName}
                        onChange={handleInputChange}
                        size="small"
                    />
                ) : (
                    params.value as string
                )
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            disableExport: true,
            renderCell: (params: GridRenderCellParams<any, any, any>) => (
                <>
                    {editRowId === params.row.id ? (
                        <>
                            <Tooltip title="Save">
                                <IconButton onClick={handleSaveClick} color="primary">
                                    <Save />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <IconButton onClick={handleCancelClick} color="secondary">
                                    <Cancel />
                                </IconButton>
                            </Tooltip>
                        </>
                    ) : (
                        <Tooltip title="Edit">
                            <IconButton onClick={() => handleEditClick(params.row.id as number)}>
                                <Edit color="primary" />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            ),
        },
    ];

    return (
        <Box sx={{ padding: 6 }}>
            <Box>
                <Alert variant="standard" severity="warning">
                    <Typography align='left'>
                        'Table Name' needs to be exactly the same as the database table name. Otherwise, users will not be able to fetch the data or might encounter issues.
                    </Typography>
                </Alert>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 3, mb:4 }}>
                <Button variant="outlined" onClick={() => window.history.back()}>Back</Button>
            </Box>
            {validationError && (<Alert variant="filled" severity="error">{validationError}</Alert>)}
            <DataGrid
                rows={tableList}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    },
                }}
                pageSizeOptions={[20, 50, 100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{ toolbar: { printOptions: { disableToolbarButton: true }, showQuickFilter: true } }}
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
            />
        </Box>
    );
};

export default AvailableDbTable;
