import api from './ApiInterceptors';

export const loginApi = (data: any) => {
    return api.post("/login", data, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
};

export const creeteUser = (data: any) => api.post("/user", data);

export const getUserById = (id: any) => api.get("/user?userId="+id);

export const getTableList = () => api.get("/table/list");

export const createNewTable = (tableName : string) => api.get("/table?tableName="+tableName);

export const getUserList = () => api.get("/api_users");

export const updateTableName =(data: any) => api.put("/table", data);

export const getScheduleTableList = () => api.get("/scheduler");

export const getSchedulerLogs = (request : any) => api.post("/scheduler_logs", request);