import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { getScheduleTableList } from '../api/Api';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
    );
}

export interface ScheduleTableType {
    id: number;
    tableName: string;
}

const ScheduleJob = (props: any) => {
    const [tableList, setTableList] = 
    useState<ScheduleTableType[]>([]);
  
    const listOfTable = () => {
        getScheduleTableList().then(response => {
            setTableList(response.data);
        }).catch(error => {
            props.handleSnackBar(true, "error", error.response?.data.message);
        });
    }

    useEffect(() => {
        if (tableList.length === 0) {
            listOfTable();
        }
    }, [tableList]);


    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1 },
        {
            field: 'tableName',
            headerName: 'TableName',
            flex: 1
        },
    ];

    return (
        <Box sx={{ padding: 6 }}>
            <Box>
                <Alert variant="standard" severity="warning">
                    <Typography align='left'>
                        'Table Name' needs to be exactly the same as the database table name. Otherwise, users will not be able to fetch the data or might encounter issues.
                    </Typography>
                </Alert>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 3, mb:4 }}>
                <Button variant="outlined" onClick={() => window.history.back()}>Back</Button>
            </Box>
            <DataGrid
                rows={tableList}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                    },
                }}
                pageSizeOptions={[20, 50, 100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar }}
                slotProps={{ toolbar: { printOptions: { disableToolbarButton: true }, showQuickFilter: true } }}
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
            />
        </Box>
    );
};

export default ScheduleJob;
